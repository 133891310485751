import React from 'react';

function Lainnya() {
  return (
    <div style={{ padding: '20px' }}>
      <h2>Halaman Lainnya</h2>
      <p>Ini adalah halaman lainnya. Anda bisa menambahkan informasi tambahan di sini.</p>
    </div>
  );
}

export default Lainnya;
